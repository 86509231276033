import React, { useState } from "react"
import Field from "./Field"
import ProfileIcon from "../../images/profile_icon_outline_small.svg"
import PhoneIcon from "../../images/phone_icon.svg"
import MailIcon from "../../images/mail_icon.svg"
import InvoiceIcon from "../../images/invoice_icon.svg"
import LocationIcon from "../../images/location_icon.svg"
import { useTranslation } from "react-i18next"
import "./ProfilePage.scss"
import { connect } from "react-redux"
import { setError } from "../../redux/actions/errorHandlerActions"
import { updateUserInfo } from "../../redux/actions/loginActions"
import NotifyWindow from "../NotifyWindow"
import { editUserInfo } from "./profileCrud"
import { withRouter } from "react-router"
import SwitchField from "./SwitchField"
import {Selector} from "./Selector";

function PersonalInformation(props) {
  const {
    FirstName: firstName,
    LastName: lastName,
    Email: email,
    ExtraEmail: extraEmail,
    Mobile: mobile,
    InvoiceName: invoiceName,
    Locations: locations,
    EmailNotification: emailNotification,
    SmsNotification: smsNotification,
    PushNotification: pushNotification,
    Language: language
  } = props.userInfo

  const [formState, setFormState] = useState({
    firstName: { value: firstName, error: "" },
    lastName: { value: lastName, error: "" },
    email: { value: email, error: "" },
    extraEmail: { value: extraEmail, error: "" },
    mobile: { value: mobile, error: "" },
    invoiceName: { value: invoiceName, error: "" },
    emailNotification: { value: emailNotification, error: "" },
    smsNotification: { value: smsNotification, error: "" },
    pushNotification: { value: pushNotification, error: "" },
    language: { value: language || (process.env.REACT_APP_APPLICATION_LANGUAGE || 'he'), error: "" },
  })
  const [showSuccessDialog, setShowSuccessDialog] = useState(false)

    const { t, i18n } = useTranslation("main");

  const generalInfo = [
    {
      label: `${t("profile.form.first_name")}:`,
      icon: ProfileIcon,
      name: "firstName",
      value: formState.firstName.value,
      disabled: true
    },
    {
      label: `${t("profile.form.last_name")}:`,
      icon: ProfileIcon,
      name: "lastName",
      value: formState.lastName.value,
      disabled: true
    },
    {
      label: `${t("profile.form.phone")}:`,
      icon: PhoneIcon,
      name: "mobile",
      value: formState.mobile.value,
      error: formState.mobile.error,
      inputMask: true,
      direction: "ltr",
      disabled: mobile
    },
    {
      label: `${t("profile.form.email")}:`,
      icon: MailIcon,
      name: "email",
      value: formState.email.value,
      error: formState.email.error,
      direction: "ltr",
      disabled: mobile
    },
    {
      label: `${t("profile.form.extra_email")}:`,
      icon: MailIcon,
      name: "extraEmail",
      value: formState.extraEmail.value,
      error: formState.extraEmail.error,
      direction: "ltr",
      disabled: false
    },
    {
      label: `${t("profile.form.invoice")}:`,
      icon: InvoiceIcon,
      name: "invoiceName",
      value: formState.invoiceName.value,
      disabled: false
    }
  ]

  const locationInfo = locations.map(el => ({
    label: `${t("profile.form.location")}:`,
    icon: LocationIcon,
    value: el.Name,
    disabled: true
  }))

  const notificationInfo = [
    {
      label: `${t("profile.form.email_notification")}`,
      name: "emailNotification",
      value: formState.emailNotification.value,
      disabled: false
    },
    {
      label: `${t("profile.form.sms_notification")}`,
      name: "smsNotification",
      value: formState.smsNotification.value,
      disabled: false
    },
    {
      label: `${t("profile.form.push_notification")}`,
      name: "pushNotification",
      value: formState.pushNotification.value,
      disabled: false
    }
  ]

  const languageInfo = [
    {
      label: `${t("profile.form.language_en")}`,
      value: 'en',
      disabled: false
    },
    {
      label: `${t("profile.form.language_he")}`,
      value: 'he',
      disabled: false
    },
  ]

  function handleFormChange(event) {
    const { name, value } = event.target
    setFormState({
      ...formState,
      [name]: { value, isInvalid: false }
    })
  }

  function handleSave(event) {
    event.preventDefault()

    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

    const isEmailInvalid =
      formState.email.value.length &&
      !emailRegex.test(formState.email.value)

    const isExtraEmailInvalid =
      formState.extraEmail.value && formState.extraEmail.value.length &&
      !emailRegex.test(formState.extraEmail.value)

    const isPhoneInvalid =
      formState.mobile.value.length &&
      !/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(
        formState.mobile.value
      )

    if (isEmailInvalid || isExtraEmailInvalid || isPhoneInvalid) {
      setFormState({
        ...formState,
        email: {
          ...formState.email,
          error: isEmailInvalid ? t("login.contact_panel.email_invalid") : ""
        },
        extraEmail: {
          ...formState.extraEmail,
          error: isExtraEmailInvalid ? t("login.contact_panel.email_invalid") : ""
        },
        mobile: {
          ...formState.mobile,
          error: isPhoneInvalid ? t("login.contact_panel.phone_invalid") : ""
        }
      })
      return
    }

    const newUserData = {
      ...props.userInfo,
      Mobile: formState.mobile.value,
      Email: formState.email.value,
      ExtraEmail: formState.extraEmail.value,
      InvoiceName: formState.invoiceName.value,
      EmailNotification: formState.emailNotification.value,
      SmsNotification: formState.smsNotification.value,
      PushNotification: formState.pushNotification.value,
      Language: formState.language.value || (process.env.REACT_APP_APPLICATION_LANGUAGE || 'he')
    }
    editUserInfo(newUserData)
      .then(() => {
        props.updateUserInfo(newUserData)
        setShowSuccessDialog(true);
        const value = (formState.language.value || (process.env.REACT_APP_APPLICATION_LANGUAGE || 'he'));
        localStorage.setItem('lang', value);
        i18n.changeLanguage(value);
      })
      .catch(error => {
        console.error(error)
        props.setError(error, t("profile.api_errors.failed_edit_user_info"))
      })
  }

  return (
    <>
      <NotifyWindow
        show={showSuccessDialog}
        primaryText={t("profile.success_window.primary")}
        confirmText={t("profile.success_window.continue")}
        onConfirm={() => {
          setShowSuccessDialog(false)
          props.history.push("/main")
        }}
        onClose={() => setShowSuccessDialog(false)}
      />
      <div className="profile-content-wrapper">
        <h1 className="mobile-only">{t("profile.personal_information")}</h1>
        <div className="content-wrapper" dir={i18n.dir()}>
          <div className="forms-wrapper">
            <h1 className="desktop-only">
              {t("profile.personal_information")}
            </h1>
            <div className="forms-section">
              {generalInfo.map((el, index) => (
                <Field
                  key={el.label}
                  label={el.error || el.label}
                  icon={el.icon}
                  name={el.name}
                  value={el.value}
                  error={el.error}
                  inputMask={el.inputMask}
                  direction={el.direction}
                  onChange={handleFormChange}
                  disabled={el.disabled}
                ></Field>
              ))}
            </div>
            <div className="divider" />
            <div className="forms-section">
              {locationInfo.map((el, index) => (
                <Field
                  key={el.label}
                  label={el.label}
                  icon={el.icon}
                  value={el.value}
                ></Field>
              ))}
            </div>
            <div className="divider" />
            <div className="alerts-form-section-text">
              <div className="alerts-form-section-text__header">{t("profile.form.alerts_and_updates")}</div>
              <div className="alerts-form-section-text__helper-text">{t("profile.form.alerts_and_updates_helper_text")}</div>
            </div>
            <div className="forms-section">
              {notificationInfo.map((el, index) => (
                <SwitchField
                  key={el.label}
                  label={el.label}
                  name={el.name}
                  value={el.value}
                  onChange={handleFormChange}
                  disabled={el.disabled}
                ></SwitchField>
              ))}
            </div>

            {
              process.env.REACT_APP_APPLICATION_LANGUAGE_USER_SELECTION_ENABLED === 'true' && (
                  <>
                    <div className="alerts-form-section-text">
                      <div className="alerts-form-section-text__header">{t("profile.form.language")}</div>
                      <div className="alerts-form-section-text__helper-text">{t("profile.form.language_text")}</div>
                    </div>

                    <div className="forms-section">
                      <Selector items={languageInfo} item={formState.language.value} submit={(ev) => {
                        handleFormChange({
                          target: {
                            name: 'language',
                            value: ev
                          }
                        })
                      }}/>
                    </div>
                  </>
                )
            }



            <div className="divider" />
            <a className="forms-save-button" onClick={handleSave}>
              {t("profile.form.save")}
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

function mapStateToProps(state) {
  return {
    userInfo: state.login.userInfo
  }
}

const mapDispatchToProps = {
  setError,
  updateUserInfo
}

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PersonalInformation)
)
