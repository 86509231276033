import React, { useState, useEffect } from "react"
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import "./index.css"
import ProfilePage from "./components/profile/ProfilePage"
import MainPage from "./components/main/MainPage"
import OrderPage from "./components/order/OrderPage"
import LoginPage from "./components/login/LoginPage"
import OrderHistoryPage from "./components/history/OrderHistoryPage"
import { connect } from "react-redux"
import { clearError } from "./redux/actions/errorHandlerActions"
import { clearSuccess } from "./redux/actions/successHandlerActions"
import { logout } from "./redux/actions/loginActions"
import FaqPage from "./components/faq/FaqPage"
import TermsPage from "./components/terms/TermsPage"
import { useTranslation } from "react-i18next"
import { PrivateRoute } from "./PrivateRoute"
import Layout from "./Layout"
import { setSearchUser } from "./redux/actions/searchActions"
import dishTagRed from "./images/dish_logo_full.svg"
import Privacy from "./components/privacy/privacy"
import {
  getCustomersList,
  getUsers,
  selectCustomer
} from "./redux/actions/adminActions"
import DeliveryPage from "./components/delivery/DeliveryPage"
import AccountPage from "./components/profile/AccountPage"
import BankAccountPage from "./components/profile-bank-account/BankAccountPage";

function App({
  isBrowserSupported,
  userInfo,
  error,
  success,
  clearError,
  clearSuccess,
  logout,
  setSearchUser,
  customers,
  selectedCustomer,
  getCustomersList,
  selectCustomer,
  getUsers
}) {
    const { t, i18n } = useTranslation("main");
  let successMessage = success.message || ""
  let errorMessage = error.message && error.status !== 401 ? error.message : ""

  if (error.status && !errorMessage) {
    switch (error.status) {
      case 401:
        errorMessage = t("errors.unauthorized")
        logout()
        setSearchUser({})
        selectCustomer(null)
        break
      case 503:
        errorMessage = t("errors.temp_error")
        break
      default:
        errorMessage = t("errors.request_failed")
        break
    }
  }
  if (errorMessage.length !== 0) {
    // Close error snackbar after 4 seconds
    setTimeout(() => clearError(), 4000)
  }
  if (successMessage.length !== 0) {
    console.log(successMessage);
    // Close error snackbar after 4 seconds
    setTimeout(() => clearSuccess(), 4000)
  }
  const isAuthenticated =
    (userInfo ? userInfo.confirmedLogin : false) &&
    errorMessage !== "Unauthorized"
  const [isLoading, setisLoading] = useState(true)

  useEffect(() => {
    if (isAuthenticated) {
      setisLoading(false)
      if (userInfo.Role === "Admin") {
        getCustomersList()
      }
    }
  }, [isAuthenticated])

  useEffect(() => {
    if (customers) {
      selectCustomer(customers[0])
    }
  }, [customers])

  useEffect(() => {
    if (selectedCustomer) {
      getUsers(selectedCustomer.CustomerID)
    }
  }, [selectedCustomer])

  if (!isBrowserSupported) {
    const contactPhone = "054-218-7014⁩"
    return (
      <div style={{ textAlign: "center" }}>
        <br></br>
        <img src={dishTagRed} alt="Dish tag" />
        <h3>
          <p direction={i18n.dir()}>
            {t("errors.unsupported_broser_or_version")}
          </p>
          <br></br>
          {contactPhone}
        </h3>
      </div>
    )
  }
  const isRoleDelivery = isAuthenticated && userInfo.Role === "Delivery"

  return (
    <BrowserRouter>
      <>
        <Layout errorMessage={errorMessage} successMessage={successMessage}></Layout>
        <Switch>
          <PrivateRoute
            path="/delivery"
            component={DeliveryPage}
            isForbidden={!isRoleDelivery}
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
          />
          <PrivateRoute
            path="/profile"
            component={ProfilePage}
            isForbidden={
              isAuthenticated &&
              ["Delivery", "Admin", "Department User"].includes(userInfo.Role)
            }
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
          />
          <PrivateRoute
            path="/account"
            component={AccountPage}
            isForbidden={
              isAuthenticated &&
              ["Delivery", "Admin", "Department User"].includes(userInfo.Role)
            }
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
          />
          <PrivateRoute
              path="/bank-profile"
              component={BankAccountPage}
              isForbidden={
                  isAuthenticated &&
                  ["Delivery", "Admin", "Department User"].includes(userInfo.Role)
              }
              isAuthenticated={isAuthenticated}
              isLoading={isLoading}
          />
          <PrivateRoute
            path="/main"
            component={MainPage}
            isForbidden={isRoleDelivery}
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
          />
          <PrivateRoute
            path="/store"
            component={MainPage}
            isForbidden={isRoleDelivery}
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
          />
          <PrivateRoute
            path="/order"
            component={OrderPage}
            isForbidden={isRoleDelivery}
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
          />
          <PrivateRoute
            path="/history"
            component={OrderHistoryPage}
            isForbidden={
              isAuthenticated &&
              ["Delivery", "Department User"].includes(userInfo.Role)
            }
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
          />
          <PrivateRoute
            path="/faq"
            component={FaqPage}
            isAuthenticated={isAuthenticated}
            isLoading={isLoading}
          />
          <Route path="/login" component={LoginPage} />
          <Route path="/terms" component={TermsPage} />
          <Route path="/privacy" component={Privacy} />

          <Redirect path="/" to={isRoleDelivery ? "/delivery" : "/main"} />
        </Switch>
      </>
    </BrowserRouter>
  )
}

function mapStateToProps(state) {
  return {
    userInfo: state.login.userInfo,
    error: state.error,
    success: state.success,
    customers: state.admin.customers,
    selectedCustomer: state.admin.selectedCustomer
  }
}

const mapDispatchToProps = {
  clearError,
  clearSuccess,
  setSearchUser,
  getCustomersList,
  getUsers,
  selectCustomer,
  logout
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
