import React from "react"
import dishTagRedFull from "../../images/dish_logo_full.svg"
import sentIcon from "../../images/login/sent_icon.svg"
import invalidInputIcon from "../../images/login/invalid_input_icon.svg"
import penIcon from "../../images/login/pen_icon.svg"
import InputMask from "react-input-mask"
import { useTranslation } from "react-i18next"
import ErrorWindow from "../ErrorWindow"

function EnterOtpPanel({
  formState,
  onChange,
  onDataChange,
  onOtpResend,
  onCloseError,
  handleSubmit
}) {
    const { t, i18n } = useTranslation("main");

  return (
    <div className="login-panel">
      <div className="enter-otp-panel">
        <div className="dish-tag">
          <img src={dishTagRedFull} alt="Dish tag full" />
          <ErrorWindow
            show={formState.error.show}
            message={formState.error.message}
            onClose={onCloseError}
          />
        </div>
        <div className="info-message">
          {formState.chosen === "email"
            ? t("login.otp_panel.send_code_email")
            : t("login.otp_panel.send_code_phone")}
        </div>
        <div className="middle-row" dir={i18n.dir()}>
          <div>
            <div className="contact-data-displayed" dir="ltr">
              <span>
                {formState.chosen === "email"
                  ? formState.email.value
                  : formState.phone.value}
              </span>
              <img src={penIcon} alt="Pen icon" onClick={onDataChange} />
            </div>
            <div className="send-again-link">
              {formState.isOtpResend ? (
                <span className="otp-is-recend-message">
                  <img src={sentIcon} alt="Sent icon" />{" "}
                  {t("login.otp_panel.otp_resend_message")}
                </span>
              ) : (
                <a href="" onClick={onOtpResend}>
                  {t("login.otp_panel.send_again")}
                </a>
              )}
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="lowest-row" dir={i18n.dir()}>
            <div
              className={
                formState.otp.isInvalid
                  ? "common-border-row invalid-input-field"
                  : "common-border-row"
              }
              dir="ltr"
            >
              <button type="submit">{t("login.otp_panel.submitbtn")}</button>
              <label>
                <span>
                  {t("login.otp_panel.enter_code")}{" "}
                  <img
                    src={invalidInputIcon}
                    alt="Invalid icon"
                    className="invalid-input-icon"
                  />
                </span>
                <InputMask
                  name="otp"
                  mask="9 9 9 9 9 9"
                  className="otp-input"
                  value={formState.otp.value}
                  onChange={onChange}
                ></InputMask>
              </label>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default EnterOtpPanel
