import React, { useEffect, useRef, useState } from "react"
import {Trans, useTranslation} from "react-i18next"
import "./OrderDishPage.scss"
import "./OrderComboDishPage.scss"
import "moment/locale/he"
import {
  saveTempItemToOrder,
  incCount
} from "../../redux/actions/cartActions.js"
import { connect } from "react-redux"
import LoadingPage from "../LoadingPage"
import { setError } from "../../redux/actions/errorHandlerActions"
import {getMealComboUserInfo, saveItemsToTempStorage} from "../main/mainCrud"
import {
  formatDateForApi,
} from "../commonFunctions"
import ErrorWindow from "../ErrorWindow";
import OrderTitle from "./OrderTitle";
import closeGray from "../../images/header/close-gray-icon.svg";
import checkMark from "../../images/dish/check_cross_mark.svg";
import questionOctagonIcon from "../../images/dish/question_octagon_icon.svg";
import ConfirmationWindow from "../ConfirmationWindow";
import {mealTypes} from "../mealTypes";
import QuantityCounter from "./QuantityCounter";
import magnifierIcon from "../../images/dish/magnifier_icon.svg";
import OrderComboOption from "./OrderComboOption";
import packageIcon from "../../images/dish/package_icon.svg";
import ItemImageHelper from "../helpers/ItemImageHelper";
import imageFiller from "../../images/image_filler_icon.svg";
import dishTagRed from "../../images/dish_logo_full.svg";

function OrderComboDishPage({
  editData,
  confirmEditOrder,
  submitData,
  removeComboId,
  goToOrder,
  comboID,
  isStore,
  supplierDocLink,
  mealID,
  userID,
  date,
  incCount,
  saveTempItemToOrder,
  paymentOptions,
    subsidyLimit,
  deliveryTime,
  ...props
}) {
    const { t, i18n } = useTranslation("main");
  const [comboData, setItemData] = useState({
    combo: null,
    isLoading: true
  })
  const [showIgnoredGroups, setShowIgnoredGroups] = useState(false)
  const [showNewOrderDialog, setShowNewOrderDialog] = useState(false)
  const [quantity, setQuantity] = useState(
      !editData ? 1 : editData.OrderItemQuantity
  )
  const [orderIdItemData, setOrderIdItemData] = useState({
    isLoading: false
  })
  const quantityIsValid =
      comboData.combo?.MealType === 4
          ? true
          : quantity > 0 && quantity <= comboData.combo?.AvilableQuantity

  const listItems = useRef(null);

  const scrollToTop = () => {
    listItems.current.scrollTo({ top: -listItems.current.scrollHeight, behavior: 'smooth' })
  }

  const selectedItems = (editData) => {
    const items = [];
    editData.Items.forEach(item => {
      items.push(...item.SelectedOptions.map(i => {
        return {
          ...i,
          ItemID: item.ItemID
        }
      }));
    });
    return items;
  }

  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false)

  const [selected, setSelected] = useState(
    !editData ? [] : editData.SelectedOptions ? editData.SelectedOptions :
        selectedItems(editData)
  )

  const infoWrapper = useRef(null)
  const [error, setError] = useState({ show: false, message: "" })
  const { innerWidth: width } = window
  const summaryPrice = selected.reduce(
      (sum, option) => sum + option.ClientPrice,
      0
  )

  useEffect(() => {
    fetchMealItemUserInfo()
  }, [])

  if (comboData.isLoading || (!!orderIdItemData && orderIdItemData.isLoading)) {
    return <LoadingPage />
  }

  const ignoredGroups = (item) => item.OptionGroups.filter(
      group => group.IsMandatory
  ).filter(
      optionGroup =>
          optionGroup.OptionGroup.Options.filter(
              option =>
                  selected.find(
                      opt => opt.id === option.id && opt.ItemOptionId === optionGroup.id
                          && opt.ItemID === item.ItemID
                  ) !== undefined
          ).length === 0
  )

  function fetchMealItemUserInfo() {
    getMealComboUserInfo(
      comboID,
      mealID,
      userID,
      formatDateForApi(date),
      props.cart[userID] ? props.cart[userID].orderID : undefined,
      editData && !editData.OrderComboID && editData.Id
        ? editData.Id
        : undefined,
      editData && editData.OrderComboID && editData.Id ? editData.Id : undefined
    )
      .then(({ data }) => {
        setItemData({ combo: data, isLoading: false })
        const options = data.Items.reduce((sum, item) => {
          sum.push(...item.OptionGroups.map(it => {
            return {
              ...it,
              ItemID: item.ItemID
            }
          }));
          return sum;
        }, []);
        const formattedSelected = []
        selected.forEach(option => {
          const optionGroup = options.find(
            group => group.OptionGroup.id === option.OptionGroupID
          )
          if (optionGroup) {
            const isFree = optionGroup.IsFree
            formattedSelected.push({
              ...option,
              OptionGroupName: optionGroup.OptionGroup.Name,
              Icon: optionGroup.OptionGroup.Icon,
              ClientPrice: isFree ? 0 : option.ClientPrice,
              CustomerPrice: isFree ? 0 : option.CustomerPrice,
              Price: isFree ? 0 : option.Price,
            })
          }
        })
        setSelected(formattedSelected)
        setTimeout(() => {
          scrollToTop();
        })
      })
      .catch(error => {
        console.error(error)
        props.setError(
          error,
          t("order_item.api_errors.failed_to_get_meal_item_user_info")
        )
      })
  }

  function validate() {
    const errorObj = {
      ignoreItems: [],
      groupWithoutMinimum: [],
    };
    comboData.combo.Items.forEach((item) => {
      if (ignoredGroups(item).length !== 0) {
        errorObj.ignoreItems.push(...ignoredGroups(item))
      } else if (groupsWithoutMinimum(item).length !== 0) {
        errorObj.groupWithoutMinimum.push(...groupsWithoutMinimum(item));
      } /*else if (!quantityIsValid) {
        setError({
          show: true,
          message: t("order_item.error_window.quantity_invalid")
        })
      }*/
    })

    if (errorObj.ignoreItems.length > 0) {
      setShowIgnoredGroups(true)
      setError({
        show: true,
        message: (
            <>
              <Trans t={t} i18nKey="order_item.error_window.options_required">
                In order to continue
                <b> you must select</b>
              </Trans>
              {errorObj.ignoreItems.map((ignoredGroup, index) => (
                  <b key={ignoredGroup.Lable}>
                    {index !== 0 && ","} {ignoredGroup.Lable}
                  </b>
              ))}
            </>
        )
      })
    } else if (errorObj.groupWithoutMinimum.length > 0) {
      setShowIgnoredGroups(true)
      setError({
        show: true,
        message: (
            <>
              {t("order_item.error_window.min_number_is_not_selected")}
              {errorObj.groupWithoutMinimum.map((group, index) => (
                  <b key={group.Lable}>
                    {index !== 0 && ","} {group.Lable}
                  </b>
              ))}
            </>
        )
      })
    } /*else if (!quantityIsValid) {
      setError({
        show: true,
        message: t("order_item.error_window.quantity_invalid")
      })
    }*/ else {
      if (editData) {
        const items = [];
        comboData.combo.Items.forEach(item => {
          const orderItem = {
            ...item,
            OrderToDate: date,
            SelectedOptions: selected.filter(element => element.ItemID === item.ItemID),
          };
          items.push(orderItem);
        })
        const data = {
          notes: '',
          items: items,
          quantity: quantity
        }
        submitData(data)
            .then(() => {
              // setShowSuccessDialog(true)
              confirmEditOrder(data)
              removeComboId()
            })
            .catch(error => {
              console.error(error)
              props.setError(
                  error,
                  t("order_item.api_errors.failed_to_edit_order")
              )
            })
      } else {
        if (subsidyLimit) {
          saveToCart(false)
        } else {
          setShowNewOrderDialog(true)
        }
      }
    }

  }

  function saveToCart(backToMeals) {
    const comboObject = {
      ComboID: comboID,
      MealID: mealID,
      ClientPayment: comboData.combo.ClientPrice,
      OrderToDate: date,
      Quantity: quantity,
      ItemName: comboData.combo.ComboName,
      ItemImages: comboData.combo.Images,
      MealDeliveryTimeId: deliveryTime?.Id,
      MealTypeName: t(
          `order_item.meal_type.${mealTypes[comboData.combo.MealType - 1]}`
      ),
      Items: [],
    }
    comboData.combo.Items.forEach(item => {
      const orderItem = {
        ItemID: item.ItemID,
        SelectedOptions: selected.filter(groupElement => groupElement.ItemID === item.ItemID),
        Notes: '',//note,
        Status: null,
        MealID: mealID,
        Cost: 0,
        CustomerPatment: 0,
        ClientPayment: item.ItemPrice,
        OrderToDate: date,
        SupplierID: item.SupplierID,
        Quantity: 1,//quantity,
        ItemName: item.ItemName,
        ItemImages: item.ItemPhoto,
        MealTypeName: t(
            `order_item.meal_type.${mealTypes[item.MealType - 1]}`
        )
      };
      comboObject.Items.push(orderItem);
    })
   saveTempMealItemToOrder(comboObject, backToMeals)
  }

  function saveTempMealItemToOrder(comboObject, backToMeals) {
    setOrderIdItemData({ isLoading: true })
    saveItemsToTempStorage(
        userID,
        props.cart[userID] ? props.cart[userID].orderID : undefined,
        comboObject,
        deliveryTime?.Id
    )
        .then(({ data }) => {
          saveTempItemToOrder(userID, data.Id)
          incCount(userID, comboObject.Quantity, data.EmployeeSum)
          setOrderIdItemData({ isLoading: false })
          if (backToMeals) {
            removeComboId()
          } else {
            goToOrder()
          }
        })
        .catch(error => {
          setOrderIdItemData({ isLoading: false })
          console.error(error)
          props.setError(error, t("order_item.api_errors.failed_to_save_to_cart"))
        })
  }

  const selectedGroups = item => item.OptionGroups.map(optionGroup => ({
    OptionGroupID: optionGroup.id,
    Lable: optionGroup.Lable,
    Min: optionGroup.OptionGroup.Min,
    IsMandatory: optionGroup.IsMandatory,
    selectedSummary: optionGroup.OptionGroup.Options.reduce((sum, option) => {
      if (
          selected.find(
              opt => opt.id === option.id && opt.ItemOptionId === optionGroup.id
          ) !== undefined
      ) {
        sum++
      }
      return sum
    }, 0)
  }))

  const groupsWithoutMinimum = item => selectedGroups(item).filter(
      group =>
          (group.selectedSummary !== 0 || group.IsMandatory) &&
          group.Min > group.selectedSummary
  )

  function selectOption(option, optionGroup, item) {
    if (
        optionGroup.OptionGroup.Max === 0 ||
        selectedGroups(item).find(group => group.OptionGroupID === optionGroup.id)
            .selectedSummary < optionGroup.OptionGroup.Max
    ) {
      const list = [
        ...selected,
        {
          ...option,
          OptionGroupName: optionGroup.OptionGroup.Name,
          Icon: optionGroup.OptionGroup.Icon,
          ClientPrice: optionGroup.IsFree ? 0 : option.ClientPrice,
          ItemOptionId: optionGroup.id,
          ItemID: item.ItemID
        }
      ];
      setSelected(list)
    }
  }

  return (
     <>
       <div className="order-item-info-wrapper" dir={i18n.dir()}>
         <div className="order-row__cancel-button cancel-header ">
           <img id="small-dish-tag" src={dishTagRed} alt="Dish tag" />
           <img
               className="order-row__cancel-button__close-icon"
               src={closeGray}
               alt="Close icon"
               onClick={() => setShowCancelConfirmation(true)}
           />
           <div className="img-partner">{t("order_item.cancel")}</div>
         </div>
         <div className="item-info-wrapper item-info-wrapper-combo" ref={infoWrapper}>
           <div className='meal-container' ref={listItems}>
             <div className='meal-side-info-container'>
               <OrderTitle title={comboData.combo.ComboName}
                           description={comboData.combo.ComboDescription}/>
               <div>
                 <div className='meal-tags-container'>
                   <div>
                     {!editData && !subsidyLimit && (
                         <QuantityCounter
                             quantityIsValid={quantityIsValid}
                             quantity={quantity}
                             setQuantity={setQuantity}
                             limit={comboData.combo.AvilableQuantity}
                         />
                     )}
                   </div>
                   {
                     <div className='tag-wrapper-container'>
                       <div className="tag-wrapper">
                         {comboData.combo.Tags.sort((a, b) => {
                           if (b.Priority < a.Priority) {
                             return -1
                           }
                           if (b.Priority > a.Priority) {
                             return 1
                           }
                           return 0
                         }).map(tag => (
                             <div key={tag.TagID} className="tag">
                               {tag.Name}
                             </div>
                         ))}
                       </div>
                     </div>
                   }
                 </div>
                 {
                   comboData.combo.Items?.map((item) => {
                     return <div key={item.ItemID}>
                       <OrderTitle title={item.ItemName} type='small'
                                   description={item.ItemDesctiption}/>
                       <div>
                         <div className="tag-wrapper">
                           {item.Tags.sort((a, b) => {
                             if (b.Priority < a.Priority) {
                               return -1
                             }
                             if (b.Priority > a.Priority) {
                               return 1
                             }
                             return 0
                           }).map(tag => (
                               <div key={tag.TagID} className="tag">
                                 <img src={tag.IconURL} alt="tag" />
                                 {tag.Name}
                               </div>
                           ))}
                         </div>
                         <div className="divider" />
                         <div className="supplier-line">
                           <img src={magnifierIcon} alt="supplier" />
                           <div className="meal-supplier">{item.SupplierName}</div>
                         </div>
                         <div className="divider" />

                         <div className="option-groups-wrapper">
                           <OrderComboOption item={item}
                                             selected={selected}
                                             ignoredGroups={ignoredGroups(item)}
                                             setSelected={setSelected}
                                             groupsWithoutMinimum={groupsWithoutMinimum(item)}
                                             showIgnoredGroups={showIgnoredGroups}
                                             selectOption={selectOption}
                           />
                         </div>
                       </div>
                     </div>
                   })
                 }



               </div>
             </div>
             <div className='meal-side-info-image-container'>
               {comboData.combo.ItemPhoto && comboData.combo.ItemPhoto.length > 5 ? (
                   <picture className="meal-img">
                     <source
                         srcSet={ItemImageHelper.larg_webp(comboData.combo.ItemPhoto)}
                         media="(min-width:1000px)"
                         type="image/webp"
                     />
                     <source
                         srcSet={ItemImageHelper.larg_jpg(comboData.combo.ItemPhoto)}
                         media="(min-width:1000px)"
                         type="image/jpg"
                     />
                     <source
                         srcSet={ItemImageHelper.small_webp(comboData.combo.ItemPhoto)}
                         type="image/webp"
                     />
                     <source
                         srcSet={ItemImageHelper.small_jpg(comboData.combo.ItemPhoto)}
                         type="image/jpg"
                     />
                     <img
                         src={ItemImageHelper.larg_jpg(comboData.combo.ItemPhoto)}
                         alt="meal"
                         className="meal-img"
                     />
                   </picture>
               ) : (
                   <img src={imageFiller} alt="meal" className="meal-img-filler" />
               )}
             </div>
           </div>
           <div className="order-row">
             <div
                 className="order-row__cancel-button"
                 onClick={() => setShowCancelConfirmation(true)}
             >
               <img
                   className="order-row__cancel-button__close-icon"
                   src={closeGray}
                   alt="Close icon"
               />
               <div className="img-partner">{t("order_item.cancel")}</div>
             </div>
             <div className="order-row__order" onClick={validate}>
               <img src={checkMark} alt="check" />
               <div className="img-partner">
                 {editData
                     ? t("order_item.change_the_order")
                     : t("order_item.order_the_dish")}
               </div>
               {summaryPrice !== 0 && (
                   <div className="order-row__summary-price">₪{summaryPrice}</div>
               )}
             </div>
           </div>
         </div>
       </div>

       <div className="content-wrapper content-wrapper-combo">
         <ErrorWindow
             show={error.show}
             message={error.message}
             onClose={() => setError({ ...error, show: false })}
         />
       </div>
       <ConfirmationWindow
           show={showNewOrderDialog}
           icon={packageIcon}
           primaryText={t("order_item.new_order_dialog.primary")}
           cancelText={t("order_item.new_order_dialog.continue")}
           confirmText={t("order_item.new_order_dialog.cancel")}
           boothWhite="true"
           onCancel={() => {
             saveToCart(true)
           }}
           onConfirm={() => {
             saveToCart(false)
           }}
           onClose={() => setShowNewOrderDialog(false)}
       />
       <ConfirmationWindow
           show={showCancelConfirmation}
           icon={questionOctagonIcon}
           primaryText={t("order_item.cancel_confirmation.primary")}
           secondaryText={t("order_item.cancel_confirmation.secondary")}
           confirmText={t("order_item.cancel_confirmation.continue")}
           cancelText={t("order_item.cancel_confirmation.cancel")}
           onConfirm={() => setShowCancelConfirmation(false)}
           onCancel={() => removeComboId()}
           onClose={() => setShowCancelConfirmation(false)}
       />
     </>
  )
}

function mapStateToProps(state) {
  return {
    cart: state.cart
  }
}

const mapDispatchToProps = {
  saveTempItemToOrder,
  incCount,
  setError
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderComboDishPage)
