import React from "react"
import Lottie from "react-lottie"
import animationData from "../lotties/start_button_anim"

function SendOtpButton() {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  }
  return <Lottie options={defaultOptions} isClickToPauseDisabled={true} />
}

export default SendOtpButton
